@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Montserrat&family=Philosopher&display=swap");

html {
  font-size: 18px;
  max-width: 100%;
  background-color: rgb(17, 17, 18);
  font-family: "Montserrat", sans-serif;
}

body {
  color: rgb(198, 212, 220);
  margin: 0 auto;
  max-width: 48rem;
  line-height: 1.45;
  padding: 0.25rem;
}

h1,
h2,
h3 {
  border-bottom: 2px solid rgb(37, 38, 40);
  margin-bottom: 1.15rem;
  padding-bottom: 0.2rem;
  text-align: center;
  font-family: "Philosopher", sans-serif;
  font-weight: 300;
}

/* Used for list titles */
h4 {
  padding: 2px 0;
  border-bottom: 2px solid rgb(37, 38, 40);
  display: inline;
}
h4::before {
  content: "⊹ ";
}
h4::after {
  content: " ⊹";
}
hr {
  border: 0;
}

h1::before {
  content: "☽⋆⁺ ";
}

h1::after {
  content: " ⁺⋆☾";
}

h2::before {
  content: "⋆⁺ ";
}

h2::after {
  content: " ⁺⋆";
}

h3::before {
  content: "⋆ ";
}

h3::after {
  content: " ⋆";
}

ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}

ul li::before {
  content: "⛧  ";
}

li > p {
  display: inline;
}

p,
ul,
h4 {
  margin-left: 12px;
  margin-right: 12px;
}

blockquote {
  border-left: 8px solid rgb(37, 38, 40);
  padding: 0 6px;
  margin: 0 42px;
}

pre,
code {
  background-color: #000000;
}

strong {
  font-weight: bold;
}

a:link {
  color: rgb(104, 177, 255);
  text-decoration: none;
}
a:visited {
  color: rgb(104, 177, 255);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: white;
}
a:active {
  text-decoration: underline;
}

.cross {
  top: 8px;
  position: absolute;
  font-size: 24px;
}
.a-special:hover {
  text-decoration: none;
  color: red;
}

.tarot-button {
  color: red;
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-size: 64px;
}

.tarot-button--small {
  font-size: 28px;
}

.tarot-button:hover {
  color: rgb(143, 11, 11);
  cursor: pointer;
  text-decoration: none;
}

.input--red {
  border: 1px solid black;
  background-color: black;
  color: red;
  margin: 0 6px;
  padding: 4px;
  border-radius: 4px;
  font-size: 16px;
}

.card {
  width: 200px;
  height: 300px;
  background-color: black;
  border: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  font-family: "Covered By Your Grace", cursive;
}

.pointer {
  cursor: pointer;
}

.card-name--Buer {
  background-image: url("/public/daemons/buer.png");
  background-size: 200px 300px;
}
.card-name--Gremory {
  background-image: url("/public/daemons/gremory.png");
  background-size: 200px 300px;
}
.card-name--Baal {
  background-image: url("/public/daemons/baal.png");
  background-size: 200px 300px;
}
.card-name--Orobas {
  background-image: url("/public/daemons/orobas.png");
  background-size: 200px 300px;
}
.card-name--Stolas {
  background-image: url("/public/daemons/stolas.png");
  background-size: 200px 300px;
}
.card-name--Furfur {
  background-image: url("/public/daemons/furfur.png");
  background-size: 200px 300px;
}
.card-name--Zagan {
  background-image: url("/public/daemons/zagan.png");
  background-size: 200px 300px;
}
.card-name--Bune {
  background-image: url("/public/daemons/bune.png");
  background-size: 200px 300px;
}
.card-name--Asmodeus {
  background-image: url("/public/daemons/asmodeus.png");
  background-size: 200px 300px;
}
.card-name--Kimaris {
  background-image: url("/public/daemons/kimaris.png");
  background-size: 200px 300px;
}
.card-name--Astaroth {
  background-image: url("/public/daemons/astaroth.png");
  background-size: 200px 300px;
}
.card-name--Auns {
  background-image: url("/public/daemons/auns.png");
  background-size: 200px 300px;
}
.card-name--Marchosias {
  background-image: url("/public/daemons/marchosias.png");
  background-size: 200px 300px;
}
.card-name--Andras {
  background-image: url("/public/daemons/andras.png");
  background-size: 200px 300px;
}
.card-name--Balam {
  background-image: url("/public/daemons/balam.png");
  background-size: 200px 300px;
}
.card-name--Vine {
  background-image: url("/public/daemons/vine.png");
  background-size: 200px 300px;
}
.card-name--Ronove {
  background-image: url("/public/daemons/ronove.png");
  background-size: 200px 300px;
}
.card-name--Orias {
  background-image: url("/public/daemons/orias.png");
  background-size: 200px 300px;
}
.card-name--Allocer {
  background-image: url("/public/daemons/allocer.png");
  background-size: 200px 300px;
}
.card-name--Vassago {
  background-image: url("/public/daemons/vassago.png");
  background-size: 200px 300px;
}
.card-name--Ipos {
  background-image: url("/public/daemons/ipos.png");
  background-size: 200px 300px;
}
.card-name--Aym {
  background-image: url("/public/daemons/aym.png");
  background-size: 200px 300px;
}

.card--front-top {
  text-align: center;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5) 95%,
    rgba(0, 0, 0, 0)
  );
  border-radius: 6px 6px 0 0;
}

.card--front-bottom {
  text-align: center;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5) 95%,
    rgba(0, 0, 0, 0)
  );
  border-radius: 0 0 6px 6px;
}

.flip-card {
  transition: transform 0.6s;
  transition-timing-function: linear;
}

.card--numeral {
  color: red;
  font-size: 20px;
  margin: 4px 0;
}

.card--name {
  font-size: 32px;
  margin: 6px 0;
}

.reverse {
  transform: rotateX(180deg) scaleX(-1);
}
